import React from 'react'
import QRCode from 'qrcode.react'
import {KTSVG} from '../../../_4threal/helpers'
import 'aframe'
import 'aframe-ar'

function Carddetail() {
  return (
    <>
      <div className='d-flex flex-row flex-wrap'>
        <div
          style={{backgroundColor: '#f3f3f3', height: '100vh', position: 'absolute', top: '0'}}
          className='d-flex flex-column col-xl-3 col-md-12 col-sm-12 pt-20'
        >
          <div className='text-center fs-4  mt-10'>Object</div>
          {/* main */}
          <div className='d-flex mt-3 px-2'>
            <div className='bg-light w-100 h-20px p-5 rounded border shadow  d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-4'>
                <i className='bi bi-card-image fs-1'></i>
                <div>Image</div>
              </div>
              <KTSVG
                path='/media/icons/duotune/general/gen041.svg'
                className='svg-icon-muted svg-icon-1hx'
              />
            </div>
          </div>
          {/* sub category start*/}
          <div className=' ps-8'>
            <div className='d-flex mt-3 px-2'>
              <div
                style={{backgroundColor: '#ced4da'}}
                className=' w-100  h-20px p-5 rounded ms-auto d-flex align-items-center justify-content-between'
              >
                <div className='d-flex align-items-center gap-4'>
                  <div className='text-dark'>Video</div>
                </div>
                <div className='d-flex align-items-center gap-2'>
                  <i className='text-dark bi bi-eye'></i>
                  <i className='text-dark bi bi-trash'></i>
                  <i className='text-dark bi bi-stickies'></i>
                </div>
              </div>
            </div>
          </div>
          {/* sub category end*/}

          {/*  */}
          <div>
            <div className='d-flex mt-3 px-2'>
              <div className='bg-light w-100 h-20px p-5 rounded border shadow  d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center gap-4'>
                  <i className='bi bi-camera-video fs-1'></i>
                  <div>Video</div>
                </div>
                <KTSVG
                  path='/media/icons/duotune/general/gen041.svg'
                  className='svg-icon-muted svg-icon-1hx'
                />
              </div>
            </div>
            {/* sub category start*/}
            <div className=' ps-8'>
              <div className='d-flex mt-3 px-2'>
                <div
                  style={{backgroundColor: '#ced4da'}}
                  className='w-100  h-20px p-5 rounded ms-auto   d-flex align-items-center justify-content-between'
                >
                  <div className='d-flex align-items-center gap-4'>
                    <div className='text-dark'>Video</div>
                  </div>
                  <div className='d-flex align-items-center gap-2'>
                    <i className='text-dark bi bi-eye'></i>
                    <i className='text-dark bi bi-trash'></i>
                    <i className='text-dark bi bi-stickies'></i>
                  </div>
                </div>
              </div>
            </div>
            {/* sub category end*/}
          </div>
          {/*  */}
          <div>
            <div className='d-flex mt-3 px-2'>
              <div className='bg-light w-100 h-20px p-5 rounded border shadow  d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center gap-4'>
                  <i className='bi bi-camera-video fs-1'></i>
                  <div>3D</div>
                </div>
                <KTSVG
                  path='/media/icons/duotune/general/gen041.svg'
                  className='svg-icon-muted svg-icon-1hx'
                />
              </div>
            </div>
            {/* sub category start*/}
            <div className=' ps-8'>
              <div className='d-flex mt-3 px-2'>
                <div
                  style={{backgroundColor: '#ced4da'}}
                  className=' w-100  h-20px p-5 rounded ms-auto d-flex align-items-center justify-content-between'
                >
                  <div className='d-flex align-items-center gap-4'>
                    <div className='text-dark'>3D View</div>
                  </div>
                  <div className='d-flex align-items-center gap-2'>
                    <i className='text-dark bi bi-eye'></i>
                    <i className='text-dark bi bi-trash'></i>
                    <i className='text-dark bi bi-stickies'></i>
                  </div>
                </div>
              </div>
            </div>
            {/* sub category end*/}
          </div>
        </div>

        <div className='d-flex flex-column flex-row-fluid'>
          <div className='d-flex flex-row flex-column-fluid'>
            <div className='d-flex flex-row-fluid bg-white flex-center'>
              <div className='App'>
                <a-scene
                  vr-mode-ui='enabled: false'
                  arjs='sourceType: webcam; videoTexture: true; debugUIEnabled: false'
                  renderer='antialias: true; alpha: true'
                >
                  <a-camera gps-new-camera='gpsMinDistance: 5'></a-camera>
                  <a-entity
                    material='color: red'
                    geometry='primitive: box'
                    gps-new-entity-place='latitude: <add-your-latitude>; longitude: <add-your-longitude>'
                    scale='10 10 10'
                  ></a-entity>
                </a-scene>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: '#f3f3f3',
            height: '100vh',
            position: 'absolute',
            top: '0',
            right: '0',
          }}
          className='d-flex flex-column col-xl-3 col-md-12 col-sm-12 pt-20'
        >
          <div className='text-center fs-4  mt-10'>Propertize</div>
          <div className='mt-20 m-auto'>
            <img src='https://dashboard.mywebar.com/_nuxt/img/1aa259b.png' />
            <div className='text-black text-center'>Nothing is selected</div>
            <div className='text-black text-center'>Select an object to view its properties</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Carddetail
