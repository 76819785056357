import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
import authReducers from "../redux/reducers/authReducer";
import planReducer from "../redux/reducers/planReducer";
import cardReducer from "../redux/reducers/cardReducer";
import adminReducer from "../redux/reducers/adminReducer";

const rootReducer = combineReducers({
    Auth: authReducers,
    Plan: planReducer,
    Card: cardReducer,
    Admin: adminReducer
});

const logger = (store: any) => (next: any) => (action: any) => {
    console.group(action.type);
    let result = next(action);
    console.groupEnd();
    return result;
};

const intialState = {};

const middlewares = [logger, thunk];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers: any = compose(...enhancers);

const store = createStore(rootReducer, intialState, composedEnhancers);

export default store;
