import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_4threal/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_4threal/assets/ts/_utils'
import { WithChildren } from '../../_4threal/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { Templatewrapper } from '../pages/dashboard/Template'
import Carddetail from '../pages/Carddetail/Carddetail'
import Changepassword from '../modules/profile/changepassword'
import Plandetail from '../pages/Plandetail/Plandetail'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const CreatevcardPage = lazy(() => import('../modules/cardform/CreatevcardPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const AnalyticsPage = lazy(() => import('../modules/analytics/AnalyticsPage'))

  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='templete' element={<Templatewrapper />} />
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />
          <Route path='carddetail' element={<Carddetail />} />
          <Route path='Plan' element={<Plandetail />} />


          {/* Lazy Modules */}
          <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/changepassword/*'
            element={
              <SuspensedView>
                <Changepassword />
              </SuspensedView>
            }
          />
          <Route
            path='/vcard/*'
            element={
              <SuspensedView>
                <CreatevcardPage />
              </SuspensedView>
            }
          />
          <Route
            path='/*'
            element={
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            }
          />
          <Route
            path='/analytics/*'
            element={
              <SuspensedView>
                <AnalyticsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route
            path='user/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
