import { API_URL } from "../../config";
import { generatePopup } from "../../utils/popup";
import * as actionTypes from "../actionTypes"
import axios from "axios";

export const authSignup: any = (payload: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: actionTypes.AUTH_REGISTER_INIT,
        });
        axios
            .post(`${API_URL}/api/users/signup`, payload)
            .then((res) => {
                if (res.status == 200) {
                    dispatch({
                        type: actionTypes.AUTH_REGISTER_SUCCESS,
                        payload: res.data,
                    });
                    generatePopup("success", "User register succesfully.");
                    window.location.replace("/auth/login");
                    resolve(res);
                } else {
                    dispatch({
                        type: actionTypes.AUTH_REGISTER_FAIL,
                        payload: res.data?.message || "Failed to signup user",
                    });
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    dispatch({
                        type: actionTypes.AUTH_REGISTER_FAIL,
                        payload:
                            typeof error == "string" ? error : "User already registered!",
                    });
                    resolve(error);
                }
            });
    });
};

export const authLogin: any = (payload: any, navigate: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: actionTypes.AUTH_LOGIN_INIT,
        });
        axios
            .post(`${API_URL}/api/users/login`, payload)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: actionTypes.AUTH_LOGIN_SUCCESS,
                        payload: res.data,
                    });
                    generatePopup("success", "User Login succesfully.");
                    window.location.replace("/dashboard");
                    resolve(res.data); // Resolve with the response data
                    // navigate("/dashboard")
                } else {
                    dispatch({
                        type: actionTypes.AUTH_LOGIN_FAIL,
                        payload: res.data?.message || "Failed to LOGIN user",
                    });
                    reject(res.data); // Reject with the error data
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    dispatch({
                        type: actionTypes.AUTH_LOGIN_FAIL,
                        payload:
                            typeof error === "string" ? error : "User already logged in!",
                    });
                    reject(error.response.data); // Reject with the error data
                } else {
                    reject(error); // Reject for other errors
                }
            });
    });
};

export const passwordChange: any = (payload: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        let token = localStorage.getItem("authToken");
        dispatch({
            type: actionTypes.PASSWORD_CHANGE_INIT
        });
        axios
            .post(`${API_URL}/api/users/change/password`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: actionTypes.PASSWORD_CHANGE_SUCCESS,
                        payload: res?.data,
                    });
                    resolve(res?.data);
                } else {
                    dispatch({
                        type: actionTypes.PASSWORD_CHANGE_FAIL,
                        payload: res?.data?.message || "Failed to user change password!",
                    });
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    dispatch({
                        type: actionTypes.PASSWORD_CHANGE_FAIL,
                    });
                } else if (error?.response?.status === 401) {
                    generatePopup("error", "Token is invalid or expired.");
                    localStorage.clear();
                    window.location.replace("/");
                }
            })
    });
};

export const userProfile: any = (payload: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        let token = localStorage.getItem("authToken");
        dispatch({
            type: actionTypes.GET_LOGINUSER_PROFILE_INIT
        });
        axios
            .get(`${API_URL}/api/users/login-user/${payload}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: actionTypes.GET_LOGINUSER_PROFILE_SUCCESS,
                        payload: res?.data,
                    });
                    resolve(res?.data);
                } else {
                    dispatch({
                        type: actionTypes.GET_LOGINUSER_PROFILE_FAIL,
                        payload: res?.data?.message || "Failed to user Data Fetch!",
                    });
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    dispatch({
                        type: actionTypes.GET_LOGINUSER_PROFILE_FAIL,
                    });
                } else if (error?.response?.status === 401) {
                    generatePopup("error", "Token is invalid or expired.");
                    localStorage.clear();
                    window.location.replace("/");
                }
            })
    });
};