import * as actionTypes from "../actionTypes";

const initState = {
    usePlan: [],
    singleplan: [],
    error: "",
};
const store = (state = initState, action: any) => {
    switch (action.type) {
        case actionTypes.GET_ALL_PLAN_INIT:
            return {
                ...state
            }
        case actionTypes.GET_ALL_PLAN_SUCCESS:
            return {
                ...state,
                usePlan: action?.payload
            }
        case actionTypes.GET_ALL_PLAN_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case actionTypes.GET_SINGLE_PLAN_INIT:
            return {
                ...state
            }
        case actionTypes.GET_SINGLE_PLAN_SUCCESS:
            return {
                ...state,
                singleplan: action?.payload
            }
        case actionTypes.GET_SINGLE_PLAN_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        default:
            return state;
    }
}

export default store;
