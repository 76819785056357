/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_4threal/helpers'
import {PageTitle} from '../../../_4threal/layout/core'
import {
  // ListsWidget2,
  // ListsWidget3,
  // ListsWidget4,
  // ListsWidget6,
  // TablesWidget5,
  TablesWidget10,
  // MixedWidget8,
  // CardsWidget7,
  // CardsWidget17,
  // CardsWidget20,
  // ListsWidget26,
  // EngageWidget10,
  // ChartsWidget1,
  ChartsWidget4,
  // TablesWidget9,
} from '../../../_4threal/partials/widgets'
// import ChartComponent from '../../../_4threal/layout/components/chart/chart1'
// import {Chart2} from '../../../_4threal/layout/components/chart/chart2'
import {Dashchart1} from '../../../_4threal/partials/widgets/charts/Dashchart1'
import {Dashchart2} from '../../../_4threal/partials/widgets/charts/Dashchart2'
import {Dashchart3} from '../../../_4threal/partials/widgets/charts/Dashchart3'
import {Dashchart4} from '../../../_4threal/partials/widgets/charts/Dashchart4'
import {Dashchart5} from '../../../_4threal/partials/widgets/charts/Dashchart5'
import {Dashchart6} from '../../../_4threal/partials/widgets/charts/Dashchart6'

const DashboardPage: FC = () => (
  <>
    {/* row-1 */}

    <div className='row w-100'>
      <div className='col-md-2 col-lg-3'>
        <Dashchart1 className='' />
      </div>
      <div className='col-md-2 col-lg-3'>
        <Dashchart2 className='' />
      </div>
      <div className='col-md-2 col-lg-3'>
        <Dashchart5 />
      </div>
      <div className='col-md-2 col-lg-3'>
        <Dashchart6 />
      </div>
    </div>
    {/* row-2 */}
    {/* <div className='w-100'>
      <ChartsWidget4 className='' />
    </div> */}
    {/* row-3 */}
    {/* <div className='row mt-2'>
      <div className='col-md-2 col-lg-4'>
        <TablesWidget10 className='' />
      </div>
      <div className='col-md-2 col-lg-4'>
        <Dashchart3 className='' />
      </div>
      <div className='col-md-2 col-lg-4'>
        <Dashchart4 className='' />
      </div>
    </div> */}
    {/* row-4 */}
    {/* <div className='row mt-5 '>
      <div className='col-md-2 col-lg-4'>
        <label className='fs-3 fw-semibold'>Lines</label>
        <div className='d-flex align-items-center flex-column mt-3 w-100'>
          <div className='d-flex justify-content-between fw-bold fs-6 text-dark opacity-75 w-100 mt-auto mb-2'>
            <span>43 Pending</span>
            <span>72%</span>
          </div>
          <div className='h-15px mx-3 w-100 bg-white bg-opacity-50 rounded border'>
            <div
              className='bg-primary rounded h-15px'
              role='progressbar'
              style={{width: '72%'}}
              aria-valuenow={50}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
        </div>
        <div className='d-flex align-items-center flex-column mt-3 w-100'>
          <div className='d-flex justify-content-between fw-bold fs-6 text-dark opacity-75 w-100 mt-auto mb-2'>
            <span>43 Pending</span>
            <span>72%</span>
          </div>
          <div className='h-15px mx-3 w-100 bg-white bg-opacity-50 rounded border'>
            <div
              className='bg-primary rounded h-15px'
              role='progressbar'
              style={{width: '72%'}}
              aria-valuenow={50}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
        </div>
        <div className='d-flex align-items-center flex-column mt-3 w-100'>
          <div className='d-flex justify-content-between fw-bold fs-6 text-dark opacity-75 w-100 mt-auto mb-2'>
            <span>43 Pending</span>
            <span>72%</span>
          </div>
          <div className='h-15px mx-3 w-100 bg-white bg-opacity-50 rounded border'>
            <div
              className='bg-primary rounded h-15px'
              role='progressbar'
              style={{width: '72%'}}
              aria-valuenow={50}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
        </div>
      </div>
      <div className='col-md-2 col-lg-4'>
        <label className='fs-3 fw-semibold'>Dynamic</label>
        <div className='d-flex align-items-center flex-column mt-3 w-100'>
          <div className='d-flex justify-content-between fw-bold fs-6 text-dark opacity-75 w-100 mt-auto mb-2'>
            <span>43 Pending</span>
            <span>72%</span>
          </div>
          <div className='h-15px mx-3 w-100 bg-secondary bg-opacity-100  border'>
            <div
              className='bg-primary  h-15px'
              role='progressbar'
              style={{width: '72%'}}
              aria-valuenow={50}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
        </div>
        <div className='d-flex align-items-center flex-column mt-3 w-100'>
          <div className='d-flex justify-content-between fw-bold fs-6 text-dark opacity-75 w-100 mt-auto mb-2'>
            <span>43 Pending</span>
            <span>72%</span>
          </div>
          <div className='h-15px mx-3 w-100 bg-secondary bg-opacity-100  border'>
            <div
              className='bg-primary  h-15px'
              role='progressbar'
              style={{width: '72%'}}
              aria-valuenow={50}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
        </div>
        <div className='d-flex align-items-center flex-column mt-3 w-100'>
          <div className='d-flex justify-content-between fw-bold fs-6 text-dark opacity-75 w-100 mt-auto mb-2'>
            <span>43 Pending</span>
            <span>72%</span>
          </div>

          <div className='h-15px mx-3 w-100 bg-secondary bg-opacity-100  border'>
            <div
              className='bg-primary  h-15px'
              role='progressbar'
              style={{width: '72%'}}
              aria-valuenow={50}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
        </div>
      </div>
      <div className='col-md-2 col-lg-4'>
        <label className='fs-3 fw-semibold'>Dynamic</label>
        <div className='d-flex align-items-center gap-4  mt-3 w-100'>
          <label className='fs-6 fw-semibold'>Dynamic</label>
          <div className='d-flex align-items-center flex-column mt-3 w-100'>
            <div className='d-flex justify-content-between fw-bold fs-6 text-dark opacity-75 w-100 mt-auto mb-2'>
              <span> Pending</span>
              <span>72%</span>
            </div>
            <div className='h-4px mx-3 w-100 bg-white bg-opacity-50 rounded border'>
              <div
                className='bg-primary rounded h-4px'
                role='progressbar'
                style={{width: '72%'}}
                aria-valuenow={50}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
          <div className='d-flex align-items-center flex-column mt-3 w-100'>
            <div className='d-flex justify-content-between fw-bold fs-6 text-dark opacity-75 w-100 mt-auto mb-2'>
              <span> Pending</span>
              <span>72%</span>
            </div>
            <div className='h-4px mx-3 w-100 bg-white bg-opacity-50 rounded border'>
              <div
                className='bg-primary rounded h-4px'
                role='progressbar'
                style={{width: '72%'}}
                aria-valuenow={50}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center gap-4  mt-3 w-100'>
          <label className='fs-6 fw-semibold'>Dynamic</label>
          <div className='d-flex align-items-center flex-column mt-3 w-100'>
            <div className='d-flex justify-content-between fw-bold fs-6 text-dark opacity-75 w-100 mt-auto mb-2'>
              <span> Pending</span>
              <span>72%</span>
            </div>
            <div className='h-4px mx-3 w-100 bg-white bg-opacity-50 rounded border'>
              <div
                className='bg-primary rounded h-4px'
                role='progressbar'
                style={{width: '72%'}}
                aria-valuenow={50}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
          <div className='d-flex align-items-center flex-column mt-3 w-100'>
            <div className='d-flex justify-content-between fw-bold fs-6 text-dark opacity-75 w-100 mt-auto mb-2'>
              <span> Pending</span>
              <span>72%</span>
            </div>
            <div className='h-4px mx-3 w-100 bg-white bg-opacity-50 rounded border'>
              <div
                className='bg-primary rounded h-4px'
                role='progressbar'
                style={{width: '72%'}}
                aria-valuenow={50}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center gap-4  mt-3 w-100'>
          <label className='fs-6 fw-semibold'>Dynamic</label>
          <div className='d-flex align-items-center flex-column mt-3 w-100'>
            <div className='d-flex justify-content-between fw-bold fs-6 text-dark opacity-75 w-100 mt-auto mb-2'>
              <span> Pending</span>
              <span>72%</span>
            </div>
            <div className='h-4px mx-3 w-100 bg-white bg-opacity-50 rounded border'>
              <div
                className='bg-primary rounded h-4px'
                role='progressbar'
                style={{width: '72%'}}
                aria-valuenow={50}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
          <div className='d-flex align-items-center flex-column mt-3 w-100'>
            <div className='d-flex justify-content-between fw-bold fs-6 text-dark opacity-75 w-100 mt-auto mb-2'>
              <span> Pending</span>
              <span>72%</span>
            </div>
            <div className='h-4px mx-3 w-100 bg-white bg-opacity-50 rounded border'>
              <div
                className='bg-primary rounded h-4px'
                role='progressbar'
                style={{width: '72%'}}
                aria-valuenow={50}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
