import clsx from 'clsx';
import { Field, Formik } from 'formik';
import React, { FC, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup'
import { getAllPlan, getPlanById, updatePlan } from '../../../../../redux/action/plan';
import { useSelector } from 'react-redux';

const UpgradePlanaModal: FC<any> = ({ closeModal, planid, setIsModalOpen }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPlanById({ _id: planid }))
    }, [planid])

    const plan = useSelector((state: any) => state.Plan?.singleplan?.data);

    const defaultValues = {
        title: plan?.name ? plan?.name : "",
        price: plan?.price ? plan?.price : "",
        features: plan?.features
            ? plan.features.map(feature => ({
                title: feature.title || "",
                supported: feature.supported || false,
            }))
            : [
                {
                    title: "",
                    supported: false,
                }
            ],

    }

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('title is required'),
        price: Yup.number()
            .typeError('Price must be a number')
            .positive('Price must be a positive number')
            .required('Price is required'),
        features: Yup.array().of(
            Yup.object().shape({
                title: Yup.string().required('Feature title is required'),
                supported: Yup.boolean().required('Support status is required'),
            })
        ),
    })

    const handleFormSubmit = async (values: any) => {
        let obj = {
            _id: planid,
            name: values?.title,
            price: values?.price.toString(),
            features: values?.features
        }
        setIsModalOpen(false)
        dispatch(updatePlan(obj)).then((res: any) => {
            if (res?.status === 200) {
                dispatch(getAllPlan())
            }
        })
    }

    return (
        <>
            <Formik
                initialValues={defaultValues}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
                enableReinitialize
            >
                {(formik) => {
                    return (
                        <form
                            id='kt_modal_add_user_form'
                            className='form'
                            encType="multipart/form-data"
                            onSubmit={formik.handleSubmit}
                        >
                            <div className='bg-white rounded-md p-5'>
                                <div>
                                    <div
                                        className='d-flex flex-column scroll-y me-n7 pe-7'
                                        id='kt_modal_add_user_scroll'
                                        data-kt-scroll='true'
                                        data-kt-scroll-activate='{default: false, lg: true}'
                                        data-kt-scroll-max-height='auto'
                                        data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                        data-kt-scroll-offset='300px'
                                    >

                                        <div className='fv-row mb-7'>
                                            <label className='required fw-bold fs-6 mb-2'>Plan Title</label>

                                            <Field
                                                placeholder='Title'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    { 'is-invalid': formik.touched.title && formik.errors.title },
                                                    {
                                                        'is-valid': formik.touched.title && !formik.errors.title,
                                                    }
                                                )}
                                                type='text'
                                                name='title'
                                                autoComplete='off'
                                                disabled={formik.isSubmitting}
                                            />
                                            {formik.touched.title && formik.errors.title && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>
                                                            {Array.isArray(formik.errors.title)
                                                                ? (formik.errors.title as string[]).join(", ")
                                                                : (formik.errors.title as string)
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className='fv-row mb-7'>
                                            <label className='required fw-bold fs-6 mb-2'>Plan price</label>

                                            <Field
                                                placeholder='price'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    { 'is-invalid': formik.touched.price && formik.errors.price },
                                                    {
                                                        'is-valid': formik.touched.price && !formik.errors.price,
                                                    }
                                                )}
                                                type='text'
                                                name='price'
                                                autoComplete='off'
                                                disabled={formik.isSubmitting}
                                            />
                                            {formik.touched.price && formik.errors.price && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>
                                                            {Array.isArray(formik.errors.price)
                                                                ? (formik.errors.price as string[]).join(", ")
                                                                : (formik.errors.price as string)
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className='fv-row mb-7'>
                                            <label className='required fw-bold fs-6 mb-2'>Plan features</label>

                                            {formik.values.features.map((feature, index) => (
                                                <>
                                                    <div key={index} className='d-flex align-items-center gap-5 mt-5'>
                                                        <div className="input-group-text">
                                                            {/* Support status */}
                                                            <input
                                                                name={`features[${index}].supported`}
                                                                type='checkbox'
                                                                checked={feature.supported}
                                                                onChange={formik.handleChange}
                                                            />
                                                        </div>
                                                        <div className='w-75'>
                                                            <input
                                                                placeholder={`Feature ${index + 1} Title`}
                                                                name={`features[${index}].title`}
                                                                type='text'
                                                                className={clsx(
                                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                                    {
                                                                        'is-invalid':
                                                                            formik.touched.features &&
                                                                            formik.touched.features[index] &&
                                                                            formik.errors.features &&
                                                                            formik.errors.features[index]?.title,

                                                                    }
                                                                )}
                                                                autoComplete='off'
                                                                disabled={formik.isSubmitting}
                                                                onChange={formik.handleChange}
                                                                value={feature.title}
                                                            />
                                                            {/* Display errors for the current feature */}
                                                            {formik.touched.features &&
                                                                formik.touched.features[index] &&
                                                                formik.errors.features &&
                                                                formik.errors.features[index]?.title && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <div className='fv-help-block'>
                                                                            <span role='alert'>
                                                                                {formik.errors.features[index]?.title}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                        {index > 0 && (
                                                            <Button
                                                                className='btn-sm btn btn-danger px-3 py-2 text-white rounded border-0'
                                                                onClick={() => {
                                                                    const newFeatures = [...formik.values.features];
                                                                    newFeatures.splice(index, 1);
                                                                    formik.setFieldValue('features', newFeatures);
                                                                }}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                                </svg>
                                                            </Button>
                                                        )}
                                                    </div>

                                                </>
                                            ))}


                                            <div className='d-flex justify-content-center mt-2'>
                                                <Button
                                                    className='btn-sm btn btn-primary px-3 py-2 text-white rounded border-0'
                                                    onClick={() => {
                                                        const newFeatures = [...formik.values.features, { title: '', supported: false }];
                                                        formik.setFieldValue('features', newFeatures);
                                                    }}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                    </svg>
                                                </Button>
                                            </div>
                                        </div>

                                    </div>



                                    <div className='text-center pt-15'>
                                        <Button
                                            type='reset'
                                            className='btn btn-light me-3'
                                            data-kt-users-modal-action='cancel'
                                            disabled={formik.isSubmitting}
                                            onClick={closeModal}
                                        >
                                            Discard
                                        </Button>

                                        <Button
                                            type='submit'
                                            className='btn btn-primary'
                                            data-kt-users-modal-action='submit'
                                            disabled={formik.isSubmitting || !formik.touched}
                                        >
                                            <span className='indicator-label'>Submit</span>
                                            {(formik.isSubmitting) && (
                                                <span className='indicator-progress'>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        </form >
                    );
                }}
            </Formik >
        </>
    )
}

export default UpgradePlanaModal