import { FC, useEffect, useState } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { decodeToken, isExpired } from "react-jwt";
import { useDispatch } from 'react-redux'
import { userProfile } from '../../redux/action/auth'
import Camera from '../../examples/image-tracking/nft/camera'
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const [token, setToken] = useState<string | null>(localStorage.getItem("authToken"));
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      const decodedToken: any = decodeToken(token); // Decoding the JWT token
      if (decodedToken) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      dispatch(userProfile(token))
    }
  }, [token])

  return isLoggedIn ? (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='/*' element={<PrivateRoutes />} />
          <Route index element={<Navigate to='/dashboard' />} />
          <Route path='/camera' element={<Camera />} />
        </Route>
      </Routes>
    </BrowserRouter>
  ) : (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='auth/*' element={<AuthPage />} />
          <Route path='*' element={<Navigate to='/auth' />} />
          <Route path='/camera' element={<Camera />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
