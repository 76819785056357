import { API_URL } from "../../config";
import { generatePopup } from "../../utils/popup";
import * as actionTypes from "../actionTypes";
import axios from "axios";

export const getAllPlan: any = (payload: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        let token = localStorage.getItem("authToken");
        dispatch({
            type: actionTypes.GET_ALL_PLAN_INIT
        });
        axios
            .get(`${API_URL}/api/plan/get-all-plan`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: payload // Assuming payload is meant to be query parameters
            })
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: actionTypes.GET_ALL_PLAN_SUCCESS,
                        payload: res?.data,
                    });
                    resolve(res?.data);
                } else {
                    dispatch({
                        type: actionTypes.GET_ALL_PLAN_FAIL,
                        payload: res?.data?.message || "Failed to get plan!",
                    });
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    dispatch({
                        type: actionTypes.GET_ALL_PLAN_FAIL,
                    });
                } else if (error?.response?.status === 401) {
                    generatePopup("error", "Token is invalid or expired.");
                    localStorage.clear();
                    window.location.replace("/");
                }
            })
    });
};

export const getPlanById: any = (payload: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        let token = localStorage.getItem("authToken");
        dispatch({
            type: actionTypes.GET_SINGLE_PLAN_INIT
        });
        axios
            .get(`${API_URL}/api/plan/singleplan/${payload?._id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: actionTypes.GET_SINGLE_PLAN_SUCCESS,
                        payload: res?.data,
                    });
                    resolve(res?.data);
                } else {
                    dispatch({
                        type: actionTypes.GET_SINGLE_PLAN_FAIL,
                        payload: res?.data?.message || "Failed to get plan!",
                    });
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    dispatch({
                        type: actionTypes.GET_SINGLE_PLAN_FAIL,
                    });
                } else if (error?.response?.status === 401) {
                    generatePopup("error", "Token is invalid or expired.");
                    localStorage.clear();
                    window.location.replace("/");
                }
            })
    });
};

export const updatePlan: any = (payload: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        let token = localStorage.getItem("authToken");
        dispatch({
            type: actionTypes.UPDATE_PLAN_INIT
        });
        axios
            .post(`${API_URL}/api/plan/updateplan`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: actionTypes.UPDATE_PLAN_SUCCESS,
                        payload: res?.data,
                    });
                    resolve(res?.data);
                    generatePopup("success", "plan update successfully.");
                } else {
                    dispatch({
                        type: actionTypes.UPDATE_PLAN_FAIL,
                        payload: res?.data?.message || "Failed to Update plan!",
                    });
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    dispatch({
                        type: actionTypes.UPDATE_PLAN_FAIL,
                    });
                } else if (error?.response?.status === 401) {
                    generatePopup("error", "Token is invalid or expired.");
                    localStorage.clear();
                    window.location.replace("/");
                }
            })
    });
};
