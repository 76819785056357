/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { KTIcon, toAbsoluteUrl } from '../../../_4threal/helpers'
import { PageTitle } from '../../../_4threal/layout/core'
import {
    ListsWidget2,
    ListsWidget3,
    ListsWidget4,
    ListsWidget6,
    TablesWidget5,
    TablesWidget10,
    MixedWidget8,
    CardsWidget7,
    CardsWidget17,
    CardsWidget20,
    ListsWidget26,
    EngageWidget10,
} from '../../../_4threal/partials/widgets'
import { UsersListSearchComponent } from '../../modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import Category from '../../../_4threal/assets/ts/components/Categoory'
import { Link } from 'react-router-dom'

const Template: FC = () => (
    <>
        <div style={{ backgroundColor: "#131313" }} className=' py-20'>
            <h1 className='text-light text-center'>What would you like to create today?</h1>
            <div className='card-title'>
                {/* begin::Search */}
                <div className='d-flex align-items-center w-50 m-auto position-relative my-8'>
                    <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                    <input
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid  ps-14'
                        placeholder='Search...'
                    />
                </div>
                {/* end::Search */}
            </div>
            <div className="">
                <ul className="navi-hover d-flex justify-content-center gap-5 mt-9  navi-active nav">
                    {Category.map((e) => {
                        return (
                            <>
                                <li className="navi-item w-75px">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2   px-3 py-1 w-100 d-flex align-items-center flex-column ">
                                        <div className="symbol symbol-30 ">
                                            <span className="symbol-label ">
                                                {e.icon}
                                            </span>
                                        </div>
                                        <div className="">
                                            <span className="text-gray-500 text-center fw-semibold fs-6">{e.name}</span>
                                        </div>
                                    </div>
                                </li>
                            </>
                        )
                    })
                    }

                </ul>

                <div />
            </div>


        </div>

        <div>
            <div className='py-4 text-black fw-bold fs-4'>Packaging</div>

            <div className='row gap-3'>


                <div className='col-xl-3 shadow w-auto rounded'>
                    <Link to="/carddetail">
                        <div className='p-3 '>
                            <img src="https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1530&q=80" className='h-150px w-250px rounded' />
                            <div className='py-1 fs-5 fw-semibold'>Business Card</div>
                            <div className='py-1 fs-6 text-gary-600'> Card</div>
                        </div>
                    </Link>
                </div>
                <div className='col-xl-3 shadow w-auto rounded'>
                    <Link to='/carddetail'>
                        <div className='p-3 '>
                            <img src="https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1530&q=80" className='h-150px w-250px rounded' />
                            <div className='py-1 fs-5 fw-semibold'>Business Card</div>
                            <div className='py-1 fs-6 text-gary-600'> Card</div>
                        </div>
                    </Link>
                </div>
                <div className='col-xl-3     shadow w-auto rounded'>
                    <Link to='carddetail'>
                        <div className='p-3 '>
                            <img src="https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1530&q=80" className='h-150px w-250px rounded' />
                            <div className='py-1 fs-5 fw-semibold'>Business Card</div>
                            <div className='py-1 fs-6 text-gary-600'> Card</div>
                        </div>
                    </Link>
                </div>

            </div>



        </div>
    </>
)

const Templatewrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>Templates</PageTitle>
            <Template />
        </>
    )
}

export { Templatewrapper }
