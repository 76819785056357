import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../_4threal/helpers'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getAllPlan } from '../../../redux/action/plan';
import UpgradePlanaModal from '../../modules/apps/admin-management/admin-edit-modal/UpgradePlanaModal';

const plans = [
    {
        title: 'BASIC PACKAGE BUSINESS CARD',
        subTitle: 'Best for startups',
        description: 'Optimal for 10+ team size and new startup',
        priceMonth: '39',
        priceAnnual: '399',
        default: true,
        custom: false,
        features: [
            {
                title: '+1 Button Choice',
                supported: true,
            },
            {
                title: 'Up to 10 Active Users',
                supported: true,
            },
            {
                title: 'Up to 30 Project Integrations',
                supported: true,
            },
            {
                title: 'Analytics Module',
                supported: true,
            },
            {
                title: 'Finance Module',
                supported: false,
            },
            {
                title: 'Accounting Module',
                supported: false,
            },
            {
                title: 'Network Platform',
                supported: false,
            },
            {
                title: 'Unlimited Cloud Space',
                supported: false,
            },
        ],
    },

    {
        title: 'PREMIERE BUSINESS PACKAGE BC/PC',
        subTitle: 'Best for 100+ team size',
        description: 'Optimal for 100+ team size and grown company',
        priceMonth: '339',
        priceAnnual: '3399',
        default: false,
        custom: false,
        features: [
            {
                title: '+3 Button Choices',
                supported: true,
            },
            {
                title: 'Up to 10 Active Users',
                supported: true,
            },
            {
                title: 'Up to 30 Project Integrations',
                supported: true,
            },
            {
                title: 'Analytics Module',
                supported: true,
            },
            {
                title: 'Finance Module',
                supported: true,
            },
            {
                title: 'Accounting Module',
                supported: true,
            },
            {
                title: 'Network Platform',
                supported: false,
            },
            {
                title: 'Unlimited Cloud Space',
                supported: false,
            },
        ],
    },

    {
        title: 'ELITE BUSINESS PACKAGE BC/PC',
        subTitle: 'Best value for 1000+ team',
        description: 'Optimal for 1000+ team and enterpise',
        priceMonth: '999',
        priceAnnual: '9999',
        label: 'Most popular',
        default: false,
        custom: false,
        features: [
            {
                title: '+4 Button Choices',
                supported: true,
            },
            {
                title: 'Up to 10 Active Users',
                supported: true,
            },
            {
                title: 'Up to 30 Project Integrations',
                supported: true,
            },
            {
                title: 'Analytics Module',
                supported: true,
            },
            {
                title: 'Finance Module',
                supported: true,
            },
            {
                title: 'Accounting Module',
                supported: true,
            },
            {
                title: 'Network Platform',
                supported: true,
            },
            {
                title: 'Unlimited Cloud Space',
                supported: true,
            },
        ],
    },

    {
        title: 'CUSTOM BUSINESS PACKAGE WE BUILD VIDEO',
        subTitle: 'Best value for 1000+ team',
        description: 'Optimal for 1000+ team and enterpise',
        priceMonth: '1599',
        priceAnnual: '15999',
        label: 'Most popular',
        default: false,
        custom: false,
        features: [
            {
                title: '+4 Button Choices',
                supported: true,
            },
            {
                title: 'Up to 10 Active Users',
                supported: true,
            },
            {
                title: 'Up to 30 Project Integrations',
                supported: true,
            },
            {
                title: 'Analytics Module',
                supported: true,
            },
            {
                title: 'Finance Module',
                supported: true,
            },
            {
                title: 'Accounting Module',
                supported: true,
            },
            {
                title: 'Network Platform',
                supported: true,
            },
            {
                title: 'Unlimited Cloud Space',
                supported: true,
            },
        ],
    }
]

interface PlanData {
    name: string;
    _id: any;
    price: any;
    features: Array<{ title: string, supported: boolean }>;
}

const Plandetail = () => {
    const [currentState, setCurrentState] = useState<'month' | 'annual'>('month')
    const [selected, setSelected] = useState('Startup')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [planid, setPlanId] = useState();
    const [allplandata, setAllplandata] = useState<PlanData[]>([]);
    const dispatch = useDispatch();

    const Allplan = useSelector((state: any) => state.Plan?.usePlan);
    const userdata = useSelector((state: any) => state.Auth?.authProfile?.data);

    const handleGetAllPlan = () => {
        dispatch(getAllPlan())
    }
    useEffect(() => {
        handleGetAllPlan();
    }, []);

    const monthly = Allplan?.data?.filter((e: any) => e?.type === "monthly");
    const annual = Allplan?.data?.filter((e: any) => e?.type === "annual");

    useEffect(() => {
        if (currentState === "month") {
            setAllplandata(monthly);
        } else {
            setAllplandata(annual);
        }
    }, [Allplan, currentState]);

    //edit modal close and open
    const openPlanEditModal: any = (id: any) => {
        setIsModalOpen(true);
        setPlanId(id);
    };

    const closePlanEditModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className='border rounded'>

            <div className='px-10 py-10'>

                {/* <div className='mb-13 text-center'>
                            <h1 className='mb-3'>Upgrade a Plan</h1>

                            <div className='text-muted fw-bold fs-5'>
                                If you need more info, please check{' '}
                                <a href='#' className='link-primary fw-bolder'>
                                    Pricing Guidelines
                                </a>
                                .
                            </div>
                        </div> */}

                <div className='d-flex flex-column'>

                    <div className='nav-group nav-group-outline mx-auto' data-kt-buttons='true'>
                        <a
                            href='#'
                            className={
                                'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
                                (currentState === 'month' && 'active')
                            }
                            onClick={() => {
                                setCurrentState('month')
                            }}
                            data-kt-plan='month'
                        >
                            Monthly
                        </a>
                        <a
                            href='#'
                            className={
                                'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
                                (currentState === 'annual' && 'active')
                            }
                            onClick={() => {
                                setCurrentState('annual')
                            }}
                            data-kt-plan='annual'
                        >
                            Annual
                        </a>
                    </div>

                    <div className='d-flex flex-wrap gap-10 justify-content-center mt-10'>
                        {allplandata?.map((plan, index) => {
                            return (
                                <div style={{ backgroundColor: "#f1f1f2" }} className="btn-outline-dashed rounded btn-color-dark d-flex flex-stack text-start w-25 h-auto justify-content-center">
                                    <div className='d-flex flex-column p-10'>

                                        <div className='d-flex'>
                                            <div>
                                                <h2 className='d-flex align-items-center fs-2 fw-bolder flex-wrap text-center'>
                                                    {plan.name}
                                                </h2>
                                            </div>
                                            {userdata?.userTypes === "admin" ?
                                                <div>
                                                    <button className='btn-sm bg-primary px-3 py-2 text-white rounded border-0' onClick={() => openPlanEditModal(plan?._id)} >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-pencil d-flex justify-content-center"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                        </svg>
                                                    </button>
                                                </div> : ""}
                                        </div>
                                        {/* <div className='fw-bold opacity-50 mt-5 w-75 m-auto text-center'>{plan.description}</div> */}

                                        <div className='ms-5 mt-5 d-flex justify-content-center'>
                                            <div>
                                                <span className='mb-2'>$</span>

                                                <span className='fs-3x fw-bolder text-primary'>
                                                    {plan.price}
                                                </span>

                                                <span className='fs-7 opacity-50'>
                                                    /<span data-kt-element='period'>Mon</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className='pt-1 mt-5'>
                                            {plan.features!.map((feature, i) => {
                                                return (
                                                    <div
                                                        className={
                                                            `d-flex align-items-center` +
                                                            (i !== plan.features!.length - 1 && ' mb-7')
                                                        }
                                                        key={`${i}-${feature.title}`}
                                                    >
                                                        {feature.supported && (
                                                            <>
                                                                <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                                                    {feature.title}
                                                                </span>

                                                                <KTIcon
                                                                    iconName='check-circle'
                                                                    className='fs-1 text-success'
                                                                />
                                                            </>
                                                        )}
                                                        {!feature.supported && (
                                                            <>
                                                                <span className='fw-bold fs-5 text-gray-400 flex-grow-1'>
                                                                    {feature.title}
                                                                </span>
                                                                <KTIcon iconName='cross-circle' className='fs-1' />
                                                            </>
                                                        )}
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <div className='d-flex flex-center flex-row-fluid pt-12'>
                                            <button type='submit' className='btn btn-primary'>
                                                Select
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* <div className='d-flex flex-center flex-row-fluid pt-12'>
                            <button type='reset' className='btn btn-light me-3' data-bs-dismiss='modal'>
                                Cancel
                            </button>

                            <button type='submit' className='btn btn-primary'>
                                Upgrade Plan
                            </button>
                        </div> */}
            </div>
            {isModalOpen &&
                <>
                    <div
                        className='modal fade show d-block'
                        id='kt_modal_add_user'
                        role='dialog'
                        tabIndex={-1}
                        aria-modal='true'
                    >
                        <div className='modal-dialog modal-dialog-centered mw-600px'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h2 className='fw-bolder'>Edit Plan</h2>
                                    <div
                                        className='btn btn-icon btn-sm btn-active-icon-primary'
                                        data-kt-users-modal-action='close'
                                        style={{ cursor: 'pointer' }}
                                        onClick={closePlanEditModal}
                                    >
                                        <KTIcon iconName='cross' className='fs-1' />
                                    </div>
                                </div>
                                <div className='modal-body scroll-y'>
                                    <UpgradePlanaModal closeModal={closePlanEditModal} setIsModalOpen={setIsModalOpen} planid={planid} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-backdrop fade show'></div>
                </>
            }
        </div>

    )
}

export default Plandetail