import * as actionTypes from "../actionTypes";

const initState = {
    loading: false,
    authProfile: [],
    error: "",
};

const store = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_REGISTER_INIT:
            return {
                ...state
            }
        case actionTypes.AUTH_REGISTER_SUCCESS:
            return {
                ...state,
                error: ""
            }
        case actionTypes.AUTH_REGISTER_FAIL:
            return {
                ...state,
                error: action?.payload
            }

        case actionTypes.AUTH_LOGIN_INIT:
            return {
                ...state
            }
        case actionTypes.AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                error: ""
            }
        case actionTypes.AUTH_LOGIN_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case actionTypes.GET_LOGINUSER_PROFILE_INIT:
            return {
                ...state
            }
        case actionTypes.GET_LOGINUSER_PROFILE_SUCCESS:
            return {
                ...state,
                authProfile: action?.payload,
                error: ""
            }
        case actionTypes.GET_LOGINUSER_PROFILE_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        default:
            return state;
    }
}
export default store;