import React, { useEffect } from 'react';
function Camera() {
    const handleTestClick = () => {
        alert('Clicked');
    };

    // useEffect(()=>{
    //     const script = document.createElement("script");

    //     script.src = "../../build/aframe-ar-nft.js";
    //     // script.src = "../aframe/build/aframe-ar-nft.js";
    //     script.async = true;
    
    //     document.body.appendChild(script);
    // },[])

    return (
        <div style={{ margin: '0', overflow: 'hidden' }}>
            {/* Minimal loader shown until image descriptors are loaded */}
            {/* <div
                className="arjs-loader"
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    zIndex: '9999',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div>Loading, please wait...</div>
            </div> */}
            <a-scene
                vr-mode-ui="enabled: false;"
                renderer="logarithmicDepthBuffer: true; precision: medium;"
                embedded
                arjs="trackingMethod: best; sourceType: webcam; debugUIEnabled: false;"
            >
                <a-assets>
                    <a-image id="twitter" src="./124021.png"></a-image>
                    <a-image id="image2" src="./124034.png"></a-image>
                    <a-image id="image3" src="./214035.png"></a-image>
                    <a-image id="image4" src="./214036.png"></a-image>
                </a-assets>

                <a-nft
                    type="nft"
                    url="./trex/trex-image/4ths"
                    smooth="true"
                    smoothCount="10"
                    smoothTolerance="0.01"
                    smoothThreshold="5"
                >
                    <a-entity>
                        <a-image
                            src="#image4"
                            position="0 2 -35 50"
                            rotation="270 0 0"
                            width="20"
                            height="20"
                        ></a-image>
                    </a-entity>
                    <a-entity position="0 0.2 -0.2">
                        <a-image
                            src="#image2"
                            position="25 2 -35"
                            rotation="270 0 0"
                            width="20"
                            height="20"
                        ></a-image>
                    </a-entity>

                    <a-entity id="twitt">
                        <a-image
                            src="#twitter"
                            position="50 2 -35"
                            rotation="270 0 0"
                            width="20"
                            height="20"
                        ></a-image>
                    </a-entity>

                    <a-entity onClick={handleTestClick}>
                        <a-image
                            src="#image3"
                            position="75 2 -35"
                            rotation="270 0 0"
                            width="20"
                            height="20"
                        ></a-image>
                    </a-entity>
                </a-nft>

                <a-entity camera></a-entity>
            </a-scene>
        </div>
    );
}

export default Camera;
