export const AUTH_REGISTER_INIT = "AUTH_REGISTER_INIT";
export const AUTH_REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";
export const AUTH_REGISTER_FAIL = "AUTH_REGISTER_FAIL";

export const AUTH_LOGIN_INIT = "AUTH_LOGIN_INIT";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";

export const PASSWORD_CHANGE_INIT = "PASSWORD_CHANGE_INIT";
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";
export const PASSWORD_CHANGE_FAIL = "PASSWORD_CHANGE_FAIL";

export const GET_LOGINUSER_PROFILE_INIT = "GET_LOGINUSER_PROFILE_INIT";
export const GET_LOGINUSER_PROFILE_SUCCESS = "GET_LOGINUSER_PROFILE_SUCCESS";
export const GET_LOGINUSER_PROFILE_FAIL = "GET_LOGINUSER_PROFILE_FAIL";

export const GET_ALL_PLAN_INIT = "GET_ALL_PLAN_INIT";
export const GET_ALL_PLAN_SUCCESS = "GET_ALL_PLAN_SUCCESS";
export const GET_ALL_PLAN_FAIL = "GET_ALL_PLAN_FAIL";

export const POST_CREATE_CARD_INIT = "POST_CREATE_CARD_INIT";
export const POST_CREATE_CARD_SUCCESS = "POST_CREATE_CARD_SUCCESS";
export const POST_CREATE_CARD_FAIL = "POST_CREATE_CARD_FAIL";

export const GET_ALL_CARD_INIT = "GET_ALL_CARD_INIT";
export const GET_ALL_CARD_SUCCESS = "GET_ALL_CARD_SUCCESS";
export const GET_ALL_CARD_FAIL = "GET_ALL_CARD_FAIL";

export const ADMIN_GET_ALLUSER_INIT = "ADMIN_GET_ALLUSER_INIT";
export const ADMIN_GET_ALLUSER_SUCCESS = "ADMIN_GET_ALLUSER_SUCCESS";
export const ADMIN_GET_ALLUSER_FAIL = "ADMIN_GET_ALLUSER_FAIL";

export const ADMIN_GET_ALLUSER_DOWNLOAD_INIT = "ADMIN_GET_ALLUSER_DOWNLOAD_INIT";
export const ADMIN_GET_ALLUSER_DOWNLOAD_SUCCESS = "ADMIN_GET_ALLUSER_DOWNLOAD_SUCCESS";
export const ADMIN_GET_ALLUSER_DOWNLOAD_FAIL = "ADMIN_GET_ALLUSER_DOWNLOAD_FAIL";

export const GET_USERBY_ID_INIT = "GET_USERBY_ID_INIT";
export const GET_USERBY_ID_SUCCESS = "GET_USERBY_ID_SUCCESS";
export const GET_USERBY_ID_FAIL = "GET_USERBY_ID_FAIL";

export const UPDATE_USER_INIT = "UPDATE_USER_INIT";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER_INIT = "DELETE_USER_INIT";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const ADD_USER_INIT = "ADD_USER_INIT";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const CARD_DELETE_INIT = "CARD_DELETE_INIT";
export const CARD_DELETE_SUCCESS = "CARD_DELETE_SUCCESS";
export const CARD_DELETE_FAIL = "CARD_DELETE_FAIL";

export const GET_SINGLE_PLAN_INIT = "GET_SINGLE_PLAN_INIT";
export const GET_SINGLE_PLAN_SUCCESS = "GET_SINGLE_PLAN_SUCCESS";
export const GET_SINGLE_PLAN_FAIL = "GET_SINGLE_PLAN_FAIL";

export const UPDATE_PLAN_INIT = "UPDATE_PLAN_INIT";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_FAIL = "UPDATE_PLAN_FAIL";