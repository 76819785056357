import * as actionTypes from "../actionTypes";

const initState = {
    UserCard: [],
    error: "",
};
const store = (state = initState, action: any) => {
    switch (action.type) {
        case actionTypes.GET_ALL_CARD_INIT:
            return {
                ...state
            }
        case actionTypes.GET_ALL_CARD_SUCCESS:
            return {
                ...state,
                UserCard: action?.payload
            }
        case actionTypes.GET_ALL_CARD_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        default:
            return state;
    }
}

export default store;
