import { KTIcon, toAbsoluteUrl } from '../../../helpers'


const Category = [

{
    name: "For You",
    icon:     <KTIcon iconName='star' className='fs-1 position-absolute' />
},
{
    name: "Business",
    icon:    <i className="bi bi-bag fs-1"></i> 
    
},
{
    name: "Marketing",
    icon:  <i className="bi bi-rocket-takeoff fs-1"></i>
},
{
    name: "Retail",
    icon:    <i className="bi bi-cart fs-1"></i>
    
},
{
    name:"Social",
    icon:    <i className="bi bi-chat-dots fs-1"></i>

}




]

export default Category